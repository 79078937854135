import { SearchInput } from '../shared/form-control/SearchInput';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import DataRow from '../shared/data-grid/DataRow';
import SortableHeading from '../shared/data-grid/SortableHeading';
import InfoIcon from '../shared/icon/InfoIcon';
import { DataJobStatus } from '../../models/DataImport';
import XIcon from '../shared/icon/XIcon';
import TrashIcon from '../shared/icon/TrashIcon';
import { DataJobStatusTag } from '../shared/data-job/DataJobStatus';
import { DataJobOverviewResponse } from '../../models/DataExport';
import DataExportService from '../../services/DataExportService';
import { ApiResponse } from '../../models/ApiResponse';
import { nextTick } from '../../utils/ReactUtils';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import { EventSystem } from '../../events/EventSystem';
import DownloadIcon from '../shared/icon/DownloadIcon';
import { FileUtils } from '../../utils/FileUtils';
import DateUtils from '../../utils/DateUtils';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import Button, { ButtonType } from '../shared/form-control/Button';
import Tooltip from '../shared/Tooltip';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import Loader from '../shared/Loader';

const OrgDataExport = () => {
  const { t } = useTranslation('organisation');
  const client = useRecoilValue(currentClientAtom);
  const [loading, setLoading] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const pageContentRef = useRef<HTMLDivElement>(null);
  const [dataExports, setDataImports] = useState<ApiResponse<DataJobOverviewResponse[]> | null>(null);
  const [sortBy, setSortBy] = useState('-createdUtc');
  const [pageNumber, setPageNumber] = useState(1);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [showLoadMore, setShowLoadMore] = useState(false);
  const debouncedSearchTerm = useDebounce(searchPhrase, 500);
  const [sortExpressions, setSortExpressions] = useState<Record<string, string>>({ ['createdUtc']: '-createdUtc' });
  const [selectedExportJob, setSelectedExportJob] = useState<DataJobOverviewResponse | null>(null);
  const toaster = useToasts();

  const exportFilter = useMemo(
    () => ({
      title: debouncedSearchTerm,
      sortBy: sortBy,
      pageSize: 15,
      pageNumber: pageNumber,
    }),
    [pageNumber, debouncedSearchTerm, sortBy],
  );

  const filterExports = useCallback(() => {
    if (client) {
      const scrollTop = pageContentRef.current?.scrollTop || 0;

      setLoading(true);
      DataExportService.getExportJobs(exportFilter).then((res) => {
        setDataImports((prev) => {
          const exportsPaged = { ...res };
          if (prev?.data && exportFilter.pageNumber > 1) {
            exportsPaged.data = [...prev.data, ...res.data];
          }
          return exportsPaged;
        });
        setShowLoadMore(res.hasNextPage || false);
        setLoading(false);

        nextTick(() => {
          pageContentRef.current?.scrollTo({ top: scrollTop });
        });
      });
    }
  }, [client, exportFilter]);

  const onLoadMore = useCallback(() => {
    setPageNumber((prev) => prev + 1);
  }, []);

  const [lastElementRef] = useInfiniteScroll(showLoadMore ? onLoadMore : null, loading);

  useEffect(() => {
    EventSystem.listen('data-export-done', filterExports);
    filterExports();
    return () => EventSystem.stopListening('data-export-done', filterExports);
  }, [filterExports]);

  const onSortBy = (expression: string) => {
    setDataImports(null);
    setPageNumber(1);
    setSortBy(expression);
  };

  const updateSortExpression = (field: string, expression: string) => {
    const expressions = { ...sortExpressions, [field]: expression };
    setSortExpressions(expressions);

    const fullExpression = Object.values(expressions).filter((x) => x !== expression);
    fullExpression.unshift(expression); // move to first

    onSortBy(fullExpression.join(','));
  };

  const confirmCancelExport = (exportJob: DataJobOverviewResponse) => {
    setSelectedExportJob(exportJob);
    setShowCancelConfirm(true);
  };

  const closeConfirmCancelExport = () => {
    setSelectedExportJob(null);
    setShowCancelConfirm(false);
  };

  const cancelExport = () => {
    if (selectedExportJob) {
      DataExportService.cancelExport(selectedExportJob?.jobId)
        .then(() => {
          toaster.addToast({
            title: t('data-exports.toasters.cancelled.title'),
            type: ToastType.SUCCESS,
          });
          filterExports();
        })
        .finally(() => closeConfirmCancelExport());
    }
  };

  const confirmDeleteExport = (exportJob: DataJobOverviewResponse) => {
    setSelectedExportJob(exportJob);
    setShowDeleteConfirm(true);
  };

  const closeConfirmDeleteExport = () => {
    setSelectedExportJob(null);
    setShowDeleteConfirm(false);
  };

  const deleteExport = () => {
    if (selectedExportJob) {
      DataExportService.deleteExport(selectedExportJob?.jobId)
        .then(() => {
          toaster.addToast({
            title: t('data-exports.toasters.deleted.title'),
            type: ToastType.SUCCESS,
            slots: {
              button: (
                <Button
                  type={ButtonType.TERTIARY}
                  onClick={() => {
                    DataExportService.undoDeleteExport(selectedExportJob?.jobId).then(() => filterExports());
                  }}
                >
                  {t('data-exports.toasters.deleted.button')}
                </Button>
              ),
            },
          });
          filterExports();
        })
        .finally(() => {
          setShowDeleteConfirm(false);
        });
    }
  };

  return (
    <div className="flex h-full flex-col pt-6" ref={pageContentRef}>
      <div className="flex justify-between">
        <Heading size={HeadingSize.H3}>{t('data-exports.heading')}</Heading>
        <div className="flex justify-between gap-4">
          <div className="w-80">
            <SearchInput
              placeholder={t('data-exports.search')}
              value={searchPhrase}
              onChange={(e) => {
                setPageNumber(1);
                setSearchPhrase(e.target.value);
              }}
              data-cy="search-export"
            />
          </div>
        </div>
      </div>
      <div className="my-3 h-full">
        <div className="-mx-6 h-full p-8">
          <div className="flex pl-4 pr-1">
            <div className="flex-grow">
              <SortableHeading
                title={t('data-exports.list.name')}
                onSort={updateSortExpression}
                expression={sortExpressions['fileName'] ?? '+fileName'}
              />
            </div>
            <div className="w-56">
              <SortableHeading
                title={t('data-exports.list.createdBy')}
                onSort={updateSortExpression}
                expression={sortExpressions['createdBy'] ?? '+createdBy'}
              />
            </div>
            <div className="w-52">
              <SortableHeading
                title={t('data-exports.list.createdDate')}
                onSort={updateSortExpression}
                expression={sortExpressions['createdUtc'] ?? '-createdUtc'}
              />
            </div>
            <div className="flex w-52 flex-col">
              <SortableHeading
                title={t('data-exports.list.status')}
                onSort={updateSortExpression}
                expression={sortExpressions['status'] ?? '+status'}
              />
            </div>
            <div className="w-20">{/* SPACER */}</div>
          </div>
          <div>
            {dataExports?.data.map((dataExport, i) => {
              const isLast = dataExports.data.length === i + 1;
              return (
                <div key={dataExport.jobId} ref={isLast ? lastElementRef : undefined}>
                  <DataRow>
                    <div className="flex-grow font-medium">
                      <Tooltip text={dataExport.fileName} truncatedTextMode>
                        {(tooltip) => (
                          <div {...tooltip} className={`max-w-[500px] truncate`}>
                            {dataExport.fileName}
                          </div>
                        )}
                      </Tooltip>
                    </div>
                    <div className="w-56">{dataExport.createdBy}</div>
                    <div className="w-52">{DateUtils.formatDateTime(new Date(dataExport.createdUtc))}</div>
                    <div className="flex w-52 flex-col justify-items-start">
                      <div className="flex justify-start">
                        <DataJobStatusTag status={dataExport.status} />
                      </div>
                    </div>
                    <div className="flex w-20 justify-end gap-4 pr-3">
                      {dataExport.status === DataJobStatus.InProgress && (
                        <Tooltip text={t('data-exports.tooltips.cancel')}>
                          {(tooltip) => (
                            <div {...tooltip}>
                              <XIcon className="h-5 w-5" onClick={() => confirmCancelExport(dataExport)} />
                            </div>
                          )}
                        </Tooltip>
                      )}
                      {dataExport.status === DataJobStatus.Completed && dataExport.fileId && (
                        <>
                          <Tooltip text={t('data-exports.tooltips.download')}>
                            {(tooltip) => (
                              <div {...tooltip}>
                                <DownloadIcon
                                  className="h-5 w-5"
                                  onClick={() =>
                                    dataExport.fileId &&
                                    FileUtils.downloadFile({ id: dataExport.fileId, name: dataExport.fileName || '', addExtension: true })
                                  }
                                />
                              </div>
                            )}
                          </Tooltip>
                          <Tooltip text={t('data-exports.tooltips.delete')}>
                            {(tooltip) => (
                              <div {...tooltip}>
                                <TrashIcon className="h-5 w-5" onClick={() => confirmDeleteExport(dataExport)} />{' '}
                              </div>
                            )}
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </DataRow>
                </div>
              );
            })}
            {loading && (
              <div className="flex flex-col items-center py-6">
                <Loader size={16} centered={false} />
              </div>
            )}
            {!dataExports?.data.length && (
              <div data-cy="public-forms-list-empty" className="flex h-full justify-center ">
                <div className="mt-36 text-center">
                  <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
                  <Heading size={HeadingSize.H3} className="my-4">
                    {t('data-exports.list.empty.heading')}
                  </Heading>
                  <div className="text-dpm-20">{t('data-exports.list.empty.sub-heading')}</div>
                </div>
              </div>
            )}
          </div>
          <ModalContext.Provider value={{ open: showCancelConfirm, onClose: closeConfirmCancelExport, modalWidth: 'w-2/5' }}>
            <ConfirmationModal
              title={t('data-exports.modals.cancel.heading')}
              description={t('data-exports.modals.cancel.description')}
              confirmText={t('data-exports.modals.cancel.confirm')}
              cancelText={t('data-exports.modals.cancel.cancel')}
              onConfirm={cancelExport}
              onCancel={closeConfirmCancelExport}
              alt
            />
          </ModalContext.Provider>
          <ModalContext.Provider value={{ open: showDeleteConfirm, onClose: closeConfirmDeleteExport, modalWidth: 'w-2/5' }}>
            <ConfirmationModal
              title={t('data-exports.modals.delete.heading')}
              description={t('data-exports.modals.delete.description')}
              confirmText={t('data-exports.modals.delete.confirm')}
              cancelText={t('data-exports.modals.delete.cancel')}
              onConfirm={deleteExport}
              onCancel={closeConfirmDeleteExport}
              alt
            />
          </ModalContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default OrgDataExport;
